import React, { useEffect, useState } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { InprintSection, InprintTitle, InprintWrapper, InprintData, InprintDataElement, InprintText } from "./inprint.style"
import Container from "../../container"


export default function Imprint(data, acf) {
    const [isReady, setIsReady] = useState(false)

    const ACF = data.acf;
    const ModuleData = data.data;


    useEffect(() => {
        setIsReady(true)
    }, [])


  return (
    <InprintSection color={ModuleData.backgroundColor}>
      {isReady && (
        <>
          <Container>
            <InprintTitle
              dangerouslySetInnerHTML={{
              __html: ACF.slogan,
              }}
            />
            <InprintWrapper>
              <InprintData>
                <tbody>
                <InprintDataElement border><td>Marka: </td><td>{ModuleData.dane.marka}</td></InprintDataElement>
                <InprintDataElement border><td>Branża: </td><td>{ModuleData.dane.branza}</td></InprintDataElement>
                <InprintDataElement border><td>Typ projektu: </td><td>{ModuleData.dane.typProjektu}</td></InprintDataElement>
                <InprintDataElement><td>Działania: </td><td>{ModuleData.dane.tagi}</td></InprintDataElement>
                </tbody>
              </InprintData>
              <InprintText
                  dangerouslySetInnerHTML={{
                  __html: ModuleData.text,
                  }}
              />
            </InprintWrapper>
          </Container>
        </>
      )}
    </InprintSection>
  )
}
