import React, { useEffect, useState, useRef } from "react"


import { ImageSection, VideoWrapper } from "./movie.style"
import Container from "../../container"
import ReactPlayer from 'react-player'

export default function Video(data, acf) {
    const [isReady, setIsReady] = useState(false)

    const ModuleData = data.data;
    const ModuleACF = data.acf;

    const videoUrl = ModuleData.video
    const youtubeUrl = ModuleData.youtube
    const vertical = ModuleData.vertical;


    const videoEl = useRef(null);


    useEffect(() => {
        setIsReady(true);
    }, [])




  return (
    <ImageSection background={ModuleData.background} backgroundColor={ModuleACF.themeColor}>
      {isReady && (
          <Container margin={true}>
            <VideoWrapper margin={ModuleData.width} vertical={vertical}>
            {videoUrl
            ?
            <video
          style={{ maxWidth: "100%", width: "100%", margin: "0 auto" }}
          playsInline
          loop
          muted
          controls="false"
          autoPlay
          src={videoUrl.mediaItemUrl}
          ref={videoEl}
        />

            :
            <ReactPlayer
              className='react-player'
              url={youtubeUrl}
              width='100%'
              height='100%'
            />
            }
            </VideoWrapper>
          </Container>
      )}
    </ImageSection>
  )
}
