import React, { useEffect, useState, useRef } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

import { ImageSection, ImageWrapper, SingleImage } from "./image.style"
import Container from "../../container"

export default function Image(data) {
    const [isReady, setIsReady] = useState(false)

    const ModuleData = data.data;

    gsap.registerPlugin(ScrollTrigger)


    const revealRefs = useRef([]);
    revealRefs.current = [];

    useEffect(() => {
      setIsReady(true)

        revealRefs.current.forEach((el, index) => {

          gsap.fromTo(el, {
            autoAlpha: 0.6,
            y: 70,
          }, {
            autoAlpha: 1,
            y: 0,
            duration: .5,
            ease: 'none',
            scrollTrigger: {
              id: `section-${index+1}`,
              trigger: el,
              start: 'top-=140 center+=40',
              end: 'center center',
              scrub: 1,
            }
          });

        });

    }, []);

    const addToRefs = el => {
      if (el && !revealRefs.current.includes(el)) {
          revealRefs.current.push(el);
      }
    };


    const Images = () => {
      switch(ModuleData.width) {

        case "full": return (
        <ImageWrapper key={ModuleData.width} width={ModuleData.width} ref={addToRefs}>
          <GatsbyImage

            image={getImage(ModuleData.image.localFile)}
            alt={ModuleData.image.alt}
            title={ModuleData.image.title}
          />
        </ImageWrapper>
        )
        case "container": return (

        <Container key={ModuleData.width} margin={true} >
        <ImageWrapper key={ModuleData.width} width={ModuleData.width} ref={addToRefs}>
          <GatsbyImage

            image={getImage(ModuleData.image.localFile)}
            alt={ModuleData.image.alt}
            title={ModuleData.image.title}
          />
          </ImageWrapper>
        </Container>
        )
        case "3/4": return (
        <Container key={ModuleData.width}>
        <ImageWrapper key={ModuleData.width} width={ModuleData.width} ref={addToRefs}>
          <GatsbyImage

            image={getImage(ModuleData.image.localFile)}
            alt={ModuleData.image.alt}
            title={ModuleData.image.title}
          />
          </ImageWrapper>
        </Container>
        )

        default: return <GatsbyImage image={getImage(ModuleData.image.localFile)} alt={ModuleData.image.alt} title={ModuleData.image.title} />
      }
    }


  return (
    <ImageSection >
      {isReady && (
        <>
        { Images() }
        </>
      )}
    </ImageSection>
  )
}
