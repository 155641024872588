import styled from "styled-components"

export const InprintSection = styled.section`
    color: #fff;
    background-color: ${props => props.color};
    padding-top: 3rem;
    padding-bottom: 3rem;

    @media ${props => props.theme.sizes.laptop} {
        max-height: 60vh;
        padding-top: 6rem;
        padding-bottom: 8rem;
    }
`
export const InprintTitle = styled.h1`
    font-size: 2.5rem;

    @media ${props => props.theme.sizes.laptop} {
        font-size: clamp(2rem, 4rem, 4rem);
    }
`
export const InprintWrapper = styled.div`
    display: flex;
    flex-direction: column;
    grid-gap: 4rem;

    @media ${props => props.theme.sizes.laptop} {
        flex-direction: row;
        grid-gap: 12rem;
    }
`
export const InprintData = styled.table`
    border-collapse: collapse;
    font-size: 1.111rem;
    font-family: ${props => props.theme.fonts.secondary};
    font-weight: 200;
`
export const InprintDataElement = styled.tr`
    border-bottom: ${props => props.border ? "1px solid #707070" : ""};

    td {
        vertical-align: top;
        padding: 1rem 0;
        font-size: 20px;

        &:first-of-type {
            width: 25%;
        }
    }

`
export const InprintText = styled.div`
    font-family: ${props => props.theme.fonts.secondary};
    font-weight: 200;
    color: #fff;


    @media ${props => props.theme.sizes.laptop} {
        max-width: 50%;
    }
`
