import React, { useEffect, useState } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { LogoSection, BackgroundImage, LogoImage } from "./logo.style"
import Container from "../../container"


export default function Logo(data, acf) {
    const [isReady, setIsReady] = useState(false)

    const ACF = data.acf;
    const ModuleData = data.data;

    useEffect(() => {
        setIsReady(true)
    }, [])


  return (
    <LogoSection color={ACF.themeColor}>
      {isReady && (
        <>
            <BackgroundImage>
              <GatsbyImage
                image={getImage(ModuleData.backgroundImage.localFile)}
                alt={ModuleData.backgroundImage.alt}
                title={ModuleData.backgroundImage.title}
              />
            </BackgroundImage>
            <LogoImage>
              <GatsbyImage
                image={getImage(ModuleData.logoImage.localFile)}
                alt={ModuleData.logoImage.alt}
                title={ModuleData.logoImage.title}
              />
            </LogoImage>
        </>
      )}
    </LogoSection>
  )
}
