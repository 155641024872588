import styled from "styled-components"

export const LogoSection = styled.section`
    position: relative;
`
export const BackgroundImage = styled.figure`
    width: 100%;
`
export const LogoImage = styled.figure`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100px;

    @media ${props => props.theme.sizes.laptop} {
        max-width: 300px;
    }
    @media ${props => props.theme.sizes.desktop} {
        max-width: 650px;
    }

`
