import * as React from "react"
import type { HeadFC, PageProps } from "gatsby"

import Layout from "../../components/layout.js"
import { SEO } from "../../components/seo"
import Imprint  from "../../components/caseStudieModules/Inprint/imprint.js"
import Logo  from "../../components/caseStudieModules/Logo/logo.js"
import Headertext  from "../../components/caseStudieModules/HeaderText/headerText.js"
import Goals  from "../../components/caseStudieModules/Goals/goals.js"
import Gallery  from "../../components/caseStudieModules/Gallery/gallery.js"
import Image  from "../../components/caseStudieModules/Image/image.js"
import Movie  from "../../components/caseStudieModules/Movie/movie.js"


export default function CaseStudie(PageProps) {
  const PageDataModules = PageProps.pageContext.CaseStudyModule.modules;
  const PageDataACF = PageProps.pageContext.ACF_case_study;

  const Modules = PageDataModules.map((moduleSingle, index) => {
    console.log(moduleSingle)
    switch(moduleSingle.module) {

      case "MODUŁ - LOGO BACKGROUND":   return <Logo key={index} data={moduleSingle.moduleLogoBackground} acf={PageDataACF} />;
      case "MODUŁ - IMPRINT":   return <Imprint key={index} data={moduleSingle.moduleImprint} acf={PageDataACF} />;
      case "MODUŁ - NAGŁÓWEK I TEKST": return <Headertext key={index} data={moduleSingle.moduleHeaderText} acf={PageDataACF} />;
      case "MODUŁ - CELE KOMUNIKACJI":  return <Goals key={index} data={moduleSingle.moduleCommunicationGoals} acf={PageDataACF} />;
      case "MODUŁ - ZDJĘCIA GALERIA":  return <Gallery key={index} data={moduleSingle.moduleImageGallery}  acf={PageDataACF}/>;
      case "MODUŁ - ZDJĘCIE SINGLE":  return <Image key={index} data={moduleSingle.moduleImageSingle} />;
      case "MODUŁ - FILM":  return <Movie key={index} data={moduleSingle.moduleMovie} acf={PageDataACF} />;

      default:      return <h1>No project match</h1>
    }
  });

  return (
    <Layout>
      {Modules}
    </Layout>
  );
}

export const Head = PageProps => {
   return (
    <SEO title={PageProps.pageContext.seo.title} description={PageProps.pageContext.seo.opengraphDescription} image={PageProps.pageContext.seo?.opengraphImage?.localFile.childImageSharp.fixed.src} pathname={PageProps.pageContext.seo.opengraphUrl} />
   )
}
