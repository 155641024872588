import styled from "styled-components"

export const GallerySection = styled.section`
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 0%;
        left: 0;
        height: 50%;
        width: 100%;
        background: ${props => props.background ? props.backgroundColor : ""};
        z-index: -1;
    }
`
export const GalleryElements = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    grid-gap: ${props => props.gridGap === 'separate' ? "1rem" : ""};
    padding: ${props => props.background ? "2rem 0" : ""};

    @media ${props => props.theme.sizes.laptop} {
        flex-wrap: wrap;
        flex-direction: row;
        padding: ${props => props.background ? "4rem 0" : ""};
    }
`
export const SingleImage = styled.figure`
    width: 100%;
    display: block;
    overflow: hidden;
    position: relative;
    transition: all 1s;
    margin: ${props => props.gridGap === 'separate' ? "1rem auto" : "0 auto"};

    @media ${props => props.theme.sizes.laptop} {
        width: calc(${props => props.widthProp}%);
    }

    &.visable {
        width: ${props => props.widthProp}%;
    }

    .gatsby-image-wrapper-constrained {
        height: 100%;
    }
`
