import styled from "styled-components"

export const HeaderTextSection = styled.section`
    position: relative;
    padding: 1rem 0;
    background :${props =>
        props.background === 'color' ? props.color
        : props.background === 'image' ? props.backgroundImage
        : props.backgorund
    };

    &.pb-0 {
        padding-bottom: 0;
    }

    @media ${props => props.theme.sizes.laptop} {
        padding: 4rem 0;
    }

`
export const HeaderTitle = styled.h2`
    font-size: clamp(1.8rem, 2rem, 2.777rem);
    color: ${props => props.textColor ? props.textColor : "black"};
`

export const Claim = styled.span`
    color: ${props => props.color};
    font-family: ${props => props.theme.fonts.primary};
    font-size: clamp(2.5rem, 3rem, 5rem);
    font-style: italic;
    font-weight: 900;
`
export const TextContent = styled.div`
    color: ${props => props.textColor ? props.textColor : "black"};
    font-family: ${props => props.theme.fonts.secondary};
    font-weight: 300;
    line-height: 34px;
    width: clamp(100%, 100%, 90ch);
    margin-top: 2rem;


    @media ${props => props.theme.sizes.laptop} {
        width: ${props => props.width ? props.width : "90ch"};
        margin-left: ${props => props.margin ? "5rem" : ""};
    }

    .inline-gatsby-image-wrapper {
        width: 100%;
        margin: 2rem 0;
    }


    em strong {
        font-family: ${props => props.theme.fonts.primary};
        line-height: 34px;
    }
    blockquote {
        font-family: ${props => props.theme.fonts.primary};
        line-height: 38px;
        font-size: 25px;
        margin-left: 5rem;
    }
`
export const PromiseContent = styled.div`
    color: ${props => props.textColor ? props.textColor : "black"};
    font-family: ${props => props.theme.fonts.secondary};
    font-weight: 300;
    line-height: 34px;
    width: clamp(100%, 100%, 90ch);
    margin-top: 2rem;


    @media ${props => props.theme.sizes.laptop} {
        width: ${props => props.width ? props.width : "90ch"};
        margin-left: ${props => props.margin ? "5rem" : ""};
    }

    .inline-gatsby-image-wrapper {
        width: 100%;
        margin: 2rem 0;
    }


    strong {
        font-family: ${props => props.theme.fonts.primary};
        line-height: 34px;
        font-size: 25px;

        @media ${props => props.theme.sizes.laptop} {
            margin-top: 2rem;
            display: block;
        }

    }
`
