import React, { useEffect, useState, useRef } from "react"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

import { HeaderTextSection, HeaderTitle, Claim, TextContent, PromiseContent } from "./headerText.style"
import Container from "../../container"


export default function Headertext(data, acf) {
    const [isReady, setIsReady] = useState(false)

    const ACF = data.acf;
    const ModuleData = data.data;

    gsap.registerPlugin(ScrollTrigger)


    const revealRefs = useRef([]);
    revealRefs.current = [];


    useEffect(() => {

        setIsReady(true)
        revealRefs.current.forEach((el, index) => {

          gsap.fromTo(el, {
            autoAlpha: 0.4,
            y: 70,
          }, {
            autoAlpha: 1,
            y: 0,
            duration: .5,
            ease: 'none',
            scrollTrigger: {
              id: `section-${index+1}`,
              trigger: el,
              start: 'top-=140 center+=40',
              end: 'center center',
              scrub: 1,
            }
          });

        });

    }, []);

    const addToRefs = el => {
      if (el && !revealRefs.current.includes(el)) {
          revealRefs.current.push(el);
      }
    };


  return (
    <HeaderTextSection className={ModuleData.szerokosc} color={ACF.themeColor} background={ModuleData.background}>
      {isReady && (
        <>
          <Container margin={true}>
            {ModuleData.header
            ?
            <HeaderTitle ref={addToRefs}    textColor={ModuleData.textColor}>{ModuleData.header}</HeaderTitle>
            :
            <></>
            }
            {ModuleData.claimCheck
              ? <Claim   color={ModuleData.claimColor}>{ModuleData.claimText}</Claim>
              : <></>
            }
            {ModuleData.obietnicaMarki
              ? <PromiseContent
              margin={ModuleData.claimCheck}
              dangerouslySetInnerHTML={{
                __html: ModuleData.obietnicaMarki,
                }}
              />
              : <></>
            }
            <TextContent
            ref={addToRefs}
                  textColor={ModuleData.textColor}
                  margin={ModuleData.claimCheck}
                  width={ModuleData.szerokosc}
                  dangerouslySetInnerHTML={{
                  __html: ModuleData.desc,
                  }}
              />

          </Container>
        </>
      )}
    </HeaderTextSection>
  )
}
