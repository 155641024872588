import styled from "styled-components"

export const ImageSection = styled.section`
    position: relative;
    padding: 4rem 0 ;

    &::after {
        content: '';
        position: absolute;
        bottom: 0%;
        left: 0;
        height: ${props => props.vertical === null ? "40%" : "100%"};
        width: 100%;
        background: ${props => props.background ? props.backgroundColor : ""};
        z-index: -1;
    }

    @media ${props => props.theme.sizes.laptop} {
        padding: 4rem 0 ;
    }

    @media ${props => props.theme.sizes.desktop} {
        padding: 6rem 0 ;
    }

    .plyr {
    --plyr-color-main: #F19206;
    --plyr-control-icon-size: 1.7rem;


    }
    .plyr__control--overlaid {
    --plyr-control-icon-size: 3.5rem;
    }
    video::-webkit-media-controls-panel {
        display: none;
    }
`
export const VideoWrapper = styled.div`
    margin: 0 auto;


    video {
        ${props => props.vertical ? "aspect-ratio: 4/3;" : "aspect-ratio: 16/9;"};
    }

    div {
        aspect-ratio: 16/9;
    }


    @media ${props => props.theme.sizes.desktop} {
        width: ${props => props.margin ? "75%" : "100%"};
    }
`

