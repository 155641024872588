import styled from "styled-components"

export const ImageSection = styled.section`
    position: relative;
    padding: 4rem 0;
    background: ${props => props.background ? props.backgroundColor : ""};
`
export const ImageWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

`

