import styled from "styled-components"

export const GoalsSection = styled.section`
    position: relative;
    padding: 2.5rem 0;

    @media ${props => props.theme.sizes.laptop} {
        padding: 4rem 0;
    }

    .goals__wrapper {
        padding: 1rem 0;
    }
`
export const GoalsTitle = styled.h2`
    font-size: clamp(1.8rem, 2rem, 2.777rem);
`
export const GoalsElements = styled.ul`
   display: flex;
   flex-wrap: wrap;
   flex-direction: column;
   list-style: none;
   padding-left: 0;
   grid-gap: 3rem 2rem;

   @media ${props => props.theme.sizes.laptop} {
        flex-direction: row;
    }
`
export const GoalsSingle = styled.li`
    font-family: ${props => props.theme.fonts.secondary};
    font-weight: 300;
    line-height: 24px;
    position: relative;
    border-left: 1px solid black;
    padding: 1rem 2rem 1rem 1rem;
    max-width: 100%;

    @media ${props => props.theme.sizes.laptop} {
        max-width: 22%;
    }
`
