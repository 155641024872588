import React, { useEffect, useState, useRef } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

import { GallerySection, GalleryElements, SingleImage } from "./gallery.style"
import Container from "../../container"

export default function Gallery(data, acf) {
    const [isReady, setIsReady] = useState(false)

    const ModuleData = data.data;
    const ModuleACF = data.acf;



      gsap.registerPlugin(ScrollTrigger)

    const revealRefs = useRef([]);
    revealRefs.current = [];


    useEffect(() => {
        setIsReady(true)
        revealRefs.current.forEach((el, index) => {

          gsap.fromTo(el, {
            autoAlpha: 1,
          }, {
            autoAlpha: 1,
            ease: 'none',
            scrollTrigger: {
              id: `section-${index+1}`,
              trigger: el,
              start: 'top-=100 center+=40',
              end: 'bottom-=100 center',
              scrub: 1,
              toggleClass: 'visable',
            }
          });

        });

    }, []);

    const addToRefs = el => {
      if (el && !revealRefs.current.includes(el)) {
          revealRefs.current.push(el);
      }
    };


    const Images = ModuleData.gallery?.map(
      (item, index) => {
        return (
          <SingleImage key={index} widthProp={item.imageWith} ref={addToRefs} gridGap={item.imageWith === '45' ? 'separate' : 'together'} className={item.imageWith === '100' ? 'full' : 'part'} >
              <GatsbyImage
                image={getImage(item.image.localFile)}
                alt={item.image.alt}
                title={item.image.title}
              />
          </SingleImage>
        )
      }
    )


  return (
    <GallerySection background={ModuleData.background} backgroundColor={ModuleACF.themeColor}>
      {isReady && (
        <>
        {ModuleData.width
        ? <Container margin={true}>
            <GalleryElements background={ModuleData.background}>
              {Images}
            </GalleryElements>
          </Container>
        :
            <GalleryElements >
              {Images}
            </GalleryElements>

        }
        </>
      )}
    </GallerySection>
  )
}
