import React, { useEffect, useState } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { GoalsSection, GoalsTitle, GoalsElements, GoalsSingle } from "./goals.style"
import Container from "../../container"


export default function Goals(data, acf) {
    const [isReady, setIsReady] = useState(false)

    const ACF = data.acf;
    const ModuleData = data.data;



    const Elements = ModuleData.goalsElements.map(
      (item, index) => {
        return (
          <GoalsSingle key={index}>
              {item.goalSingle}
          </GoalsSingle>
        )
      }
    )

    useEffect(() => {
        setIsReady(true)
    }, [])


  return (
    <GoalsSection color={ACF.themeColor}>
      {isReady && (
        <>
          <Container margin={true}>
            <GoalsTitle>{ModuleData.header}</GoalsTitle>
              <div className="goals__wrapper">
                <GoalsElements>{Elements}</GoalsElements>
              </div>
          </Container>
        </>
      )}
    </GoalsSection>
  )
}
